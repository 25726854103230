export type EventLinkEnvironment =
  | "production"
  | "development"
  | "localhost"
  | "sandbox";

const redirectUri = {
  production: "https://app.event.dev/connections/oauth/callback",
  development: "https://development.event.dev/connections/oauth/callback",
  sandbox: "https://sandbox.event.dev/connections/oauth/callback",
  localhost: "http://localhost:4202/connections/oauth/callback",
};

export const getEnvFromHost = () => {
  try {
    if (window?.location) {
      if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
        return "localhost";
      }

      const subdomain = window.location.host.split(".")[0];
      switch (subdomain) {
        case "authkit":
          return "production";
        case "sandbox-authkit":
          return "sandbox";
        case "development-authkit":
          return "development";
        default:
          return subdomain as EventLinkEnvironment;
      }
    } else {
      return "production";
    }
  } catch (error) {
    return "localhost";
  }
};

export const EVENT_INC_DOMAIN = "integrationos.com";

export const hostList = {
  localhost: `development-authkit.${EVENT_INC_DOMAIN}`,
  development: `development-authkit.${EVENT_INC_DOMAIN}`,
  sandbox: `sandbox-authkit.${EVENT_INC_DOMAIN}`,
  production: `authkit.${EVENT_INC_DOMAIN}`,
  //This is for typescript on the server and this arm will never be reached on the browser
  "": `development-authkit.${EVENT_INC_DOMAIN}`,
};

export const getRedirectUriFromHost = () => {
  try {
    if (window?.location) {
      if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
        return redirectUri.localhost;
      }
    }

    const subdomain = window.location.host.split(".")[0];

    switch (subdomain) {
      case "authkit":
        return redirectUri.production;
      case "sandbox-authkit":
        return redirectUri.sandbox;
      case "development-authkit":
        return redirectUri.development;
      default:
        return redirectUri.development;
    }
  } catch (error) {
    return redirectUri.development;
  }
};
