import axios from "axios";
import { getEnvFromHost } from "../../helpers/getEnvFromHost";

export const apiRequest = async ({
  url,
  method,
  payload,
  headers,
}: {
  url: string;
  method: "POST" | "GET";
  payload: object;
  headers?: object;
}) => {
  const { data } = await axios({
    url,
    method,
    data: payload,
    headers,
  });

  return data;
};

const domains = {
  localhost: "http://localhost:3005",
  development: "https://development-api.integrationos.com",
  sandbox: "https://sandbox-api.integrationos.com",
  production: "https://api.integrationos.com",
  default: "https://development-api.integrationos.com",
  "": "https://development-api.integrationos.com",
};

const oldDomains = {
  localhost: "http://localhost:3001",
  development: "https://development-api.integrationos.com",
  sandbox: "https://sandbox-api.integrationos.com",
  production: "https://api.integrationos.com",
  default: "https://development-api.integrationos.com",
  "": "https://development-api.integrationos.com",
};

export function getUrl(type: "old" | "new" = "new") {
  const env = getEnvFromHost();

  if (!env) {
    return domains.development;
  }
  const domain = type === "old" ? oldDomains[env] : domains[env];
  return domain ?? domains.development;
}

export const path = getUrl();
export const oldPath = getUrl("old");

export const apiKeys = {
  "get.integration.definitions.list": `${oldPath}/public/v1/integration-definitions/list`,
  "connection.definition": `${path}/v1/public/connection-definitions`,
  "integration.create": `${oldPath}/public/v1/links/create-integration`,
  "connection.create": `${oldPath}/public/v1/event-links/create-embed-connection`,
  "track": `${oldPath}/public/v1/tracking/track`,
  "get.embed.token": `${oldPath}/public/v1/embed-tokens/get`,
  "update.embed.token": `${oldPath}/public/v1/embed-tokens/update`,
  'connection.oauth.definition': `${path}/v1/public/connection-oauth-definition-schema?limit=100`,
};

export const remoteKeys = {
  getIntegrationDefinitions: "integration.definitions.get",
  listIntegrationDefinitions: "integration.definitions.list",
  getConnectionDefinition: "connection.definition.get",
  listConnectionDefinitions: "connection.definitions.list",
  getEmbedToken: "embed.token.get",
  updateEmbedToken: "embed.token.update",
  getConnectionOauthDefinition: "connection.oauth.definition.get",
};
